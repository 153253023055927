import React, { useRef, useState, useEffect } from 'react';
import Get from '../Fech/Get';
import { Link } from "react-router-dom";
import Header from '../components/Header/Header';
import Store from '../Fech/Store';
const ListQestionPage = () => {
    const [questions, setQuestions] = useState([]);
    const [tag, setTag] = useState(null);
    const [loaded, SetLoaded] = useState('');
    const getQuestion = async () => {

        if (Store.Question.length == 0) {
            let data = await Get.post('get_questions_list', {
                key: Get.key,
                hash: Get.userhash,
                // tag: 'All'
            });
            Store.Question = data;
        }

        return Store.Question;
    }
    const getTag = async () => {
        var urlParams = new URLSearchParams(window.location.search);
        let tag = urlParams.get('tag');
        setTag(tag);
        let questions = await getQuestion();

        let tagQuestion = [];
        questions.map((el, index) => {
            if (typeof el.tags == 'object' && el.tags !== null && el.tags.includes(tag)) {
                tagQuestion.push(el);
            }
        });
        setQuestions(tagQuestion);
    }
    const searchUrl = () => {
        let tag = null;
        let interval = setInterval(() => {
            var urlParams = new URLSearchParams(window.location.search);
            tag = urlParams.get('tag');
            setTag(tag);

        }, 500);
    }
    useEffect(() => {
        const as = async () => {
            SetLoaded('loaded');
            await getTag();
            SetLoaded('');
            searchUrl();
        }
        as();
    }, [tag]);
    return (
        <>
            <Header></Header>

            <div className={"main"}>
                <div className={`scroll-questions container ${loaded}`} style={{ minHeight: '500px' }}>
                    {
                        Array.isArray(questions) && questions.length > 0 ?
                            questions.map((question, index) => {
                                return <Link to={`/questionpage?id=${question.id}`} key={index} className='question-item'>
                                    <div className='add-to-favorites'></div>
                                    <div className='question-title'>{question.name}</div>
                                    <div className='question-options'>
                                        <div className='question-votes'>{question.respondents} votes</div>
                                        <div className='question-data'>{Get.dateFormatTimestapTime(question.date)}</div>
                                    </div>
                                </Link>
                            }) : <h3>NO RESULT</h3>}
                </div>
            </div>


        </>
    );
}
export default ListQestionPage;