import React from 'react';
import './footer.css';
import { Link } from "react-router-dom";
function Footer(props) {
    // console.log(props.submitValue)
    // console.log(props.marginValue)

    let none = '';
    if (props.submitValue === 'none') {
        none = 'none'
    }

    let marginTop = '';
    if (props.marginValue === 'marginTopZero') {
        marginTop = 'marginTop'
    } else {
        marginTop = 'footer'
    }

    return (
        <div className={marginTop}>
            {/*<img*/}
            {/*    src={require('../../images/Logo1.svg').default}*/}
            {/*    alt=""*/}
            {/*    className={"footerImgBackground firstQuestionResultContentFooter3"}*/}
            {/*/>*/}

            {/*<div*/}
            {/*    className={*/}
            {/*        "footerImgBackground firstQuestionResultContentFooter3 firstQuestionResultContentFooter5"*/}
            {/*    }>*/}
            {/*</div>*/}

            <div className={"inputButton"} style={{ display: none }}>
                <Link to="/">
                    <img src={require('../../images/Frame.svg').default} alt="" />
                </Link>
                <input type="submit" value={props.submitValue} />
            </div>


            <div className={"enjoy"}>
                <div
                    className={
                        "footerImgBackground firstQuestionResultContentFooter3 firstQuestionResultContentFooter5"
                    }>
                </div>
                <div className={"enjoyBlock1"}>enjoy to similar polls</div>
                <div className={"hashtagsBlock"}>
                    <div><span>#</span>covid</div>
                    <div><span>#</span>medicine</div>
                    <div><span>#</span>epidemy</div>
                </div>
                <div className={"enjoyBlock2"}>or create your own</div>
            </div>
        </div>


    );
}

export default Footer;