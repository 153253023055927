import React, { useState, useEffect } from 'react';
import Header from "../Header/Header";
import './subscribedUserQuestion.css';
import Get from '../../Fech/Get';
import { Navigate, Link } from 'react-router-dom';
function SubscribedUserQuestion(props) {
    const [aded, setAded] = useState(0);
    const [answers, setAnswers] = useState(["Option 1", "Option 2", "Option 3"])
    const [error, setError] = useState('');
    const submitAnswer = async (e) => {
        e.preventDefault();
        let fields = document.querySelectorAll(".ansfers-fields").length;
        console.log(answers)
        if (answers.length < 2) {

            setError('At least two answers are required.');
            return;
        }

        let loaded = document.querySelector(".loaded-block")
        loaded.classList.add("loaded");
        let formEntries = new FormData(e.target).entries();
        let data = {};
        let formArray = formEntries.toArray();
        formArray.map((el, index) => {
            if (el[0].includes('[]')) {
                let key = el[0].replace('[]', '');
                if (!data.hasOwnProperty(key)) {
                    data[key] = [];
                }
                data[key].push(el[1]);
            } else {
                data[el[0]] = el[1];
            }
        });

        data.key = Get.key;
        data.hash = Get.userhash;

        let result = await Get.post('create_question', data);
        // console.log(Get.objectToForm(data));
        loaded.classList.remove("loaded");
        setAded(1);
    }
    const focusAnsfer = () => {
        setError('');
    }
    const setAnswer = () => {
        setAnswers([...answers, ""])
    }
    const setValue = (index, value) => {
        let newArr = [...answers];
        newArr[index] = value;
        setAnswers(newArr);
    }
    const removeBlockInput = (index) => {
        answers.splice(index, 1);

        setAnswers([...answers]);
    }
    return (
        aded == 1 ? <Navigate to="/" /> :
            <div>
                <Header showHide={'none'}></Header>

                <div className={"main"}>
                    <form className='loaded-block' onSubmit={(e) => { submitAnswer(e) }}>
                        <div className='container'>
                            <input type="text" className={"SubscribedUserQuestionBlock1"} required name='question' placeholder="Your question" />
                        </div>
                        <hr className={"SubscribedUserQuestionBlock1Hr"} />
                        <div className='container'>

                            {answers.map((el, index) => {
                                return <div className='flex-betwen input-delete ansfers-fields' key={index}>
                                    <input type="text" className={"SubscribedUserQuestionBlock"} onFocus={() => { focusAnsfer() }} required name='answers[]' onChange={e => setValue(index, e.target.value)}  placeholder={el} />
                                    <div className='delete-input' onClick={() => removeBlockInput(index)}></div>
                                </div>
                            })}

                        </div>
                        <div className='pt-20'></div>
                        <div className='error container'>{error}</div>

                        <hr className={"SubscribedUserQuestionBlock2Hr"} />
                        <div className='link-add container'>
                            <span className='create-ansfer' onClick={() => { setAnswer() }}>+ add option</span>
                        </div>
                        <hr className={"SubscribedUserQuestionBlock2Hr"} />
                        {/* <input type="text" className={"SubscribedUserQuestionBlock2"} value="Yes" placeholder="Yes" />
                <input type="text" className={"SubscribedUserQuestionBlock2"} value="No" placeholder="No" />
                <div className={"SubscribedUserQuestionBlock2"}>Officially no</div> */}
                        <div className='container'>
                            <input type="text" className={"SubscribedUserQuestionBlock3"} name='tags' required placeholder='add 3 tags, separated, by comma' />
                        </div>
                        
                        <div className={"inputButton"}>
                            <Link to="/">
                                <img src={require('../../images/Frame.svg').default} alt="" />
                            </Link>
                            <input type="submit" value="add answer" />
                        </div>

                        <div className={"SubscribedUserQuestionBlock4"}>
                            <div className={"SubscribedUserQuestionFooter"}></div>
                            <div className={"SubscribedUserQuestionBlock4Title"}>ready to publish?</div>
                            <div className={"SubscribedUserQuestionBlock4Submit"}>yes, submit</div>
                        </div>
                    </form>
                </div>

            </div>
    );
}

export default SubscribedUserQuestion;