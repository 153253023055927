import React, { useRef, useState, useEffect } from 'react';
import Get from '../Fech/Get';
import { Link } from "react-router-dom";
import Header from '../components/Header/Header';
import Store from '../Fech/Store';
const ListQestionPages = () => {
    const [questions, setQuestions] = useState([]);
    const [tag, setTag] = useState(null);
    const [loaded, SetLoaded] = useState('');
    const getQuestion = async (query = 'get_questions_list') => {

        if (Store.Question.length == 0) {
            let data = await Get.post(query, {
                key: Get.key,
                hash: Get.userhash,
                // tag: 'All'
            });
            Store.Question = data;
        }

        return Store.Question;
    }
    const getTag = async () => {
        var urlParams = new URLSearchParams(window.location.search);
        let pols = urlParams.get('pols');
        let query = 'get_questions_list';
        switch (pols) {
            case 'new':
                query = 'get_questions_list_new';
                break;
            case 'top':
                query = 'get_questions_list_top';
                break;
            case 'myown':
                query = 'get_questions_list_myown';
                break;
            case 'hot':
                query = 'get_questions_list_hot';
                break;
            case 'voted':
                query = 'get_questions_list_voted';
                break;
            case 'favorites':
                query = 'get_favorites';
                break;

        }
        let questions = await getQuestion(query);
        if (Array.isArray(questions) && questions.length !== 0)
            setQuestions(questions);
    }

    useEffect(() => {
        const as = async () => {
            SetLoaded('loaded');
            await getTag();
            SetLoaded('');
        }
        as();
    }, [tag]);
    return (
        <>
            <Header></Header>

            <div className={"main"}>
                <div className={`scroll-questions container ${loaded}`} style={{ minHeight: '500px' }}>
                    {

                        questions.map((question, index) => {
                            return <Link to={`/questionpage?id=${question.id}`} key={index} className='question-item'>
                                <div className='add-to-favorites'></div>
                                <div className='question-title'>{question.name}</div>
                                <div className='question-options'>
                                    <div className='question-votes'>{question.respondents} votes</div>
                                    <div className='question-data'>{Get.dateFormatTimestapTime(question.date)}</div>
                                </div>
                            </Link>
                        })}
                </div>
            </div>


        </>
    );
}
export default ListQestionPages;