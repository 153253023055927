import React from 'react';
import SubscribedUserQuestionContent from '../../src/components/subscribedUserQuestion/subscribedUserQuestion';
import Pages from "../components/pages";
function SubscribedUserQuestion(props) {
    return (
        <div>
            <SubscribedUserQuestionContent></SubscribedUserQuestionContent>


        </div>
    );
}

export default SubscribedUserQuestion;