import React, { useRef, useState, useEffect } from 'react';
import Slider from '../MainSlider/MainSlider';
import Notification from '../Notification/Notification'
import Slider2 from '../MainSlider2/MainSlider2';
import QuestionsListTabs from '../QuestionsListTabs/QuestionsListTabs';
import Header from "../Header/Header";
import Pages from "../pages";
import Get from '../../Fech/Get';
import Store from '../../Fech/Store';
const Main = (props) => {


    const question = useRef(null);
    const getQuestion = async () => {

        if (Store.Question.length == 0) {
            let data = await Get.post('get_questions_list', {
                key: Get.key,
                hash: Get.userhash,
                // tag: 'All'
            });
            Store.Question = data;
        }

        return Store.Question;
    }

    const setQuestion = (data) => {

        if (question.current !== null) {
            question.current.questionSet(data);
        }
    }
    useEffect(() => {
        const as = async () => {
            let data = await getQuestion();
            setQuestion(data);
        }
        as();
    }, [])
    return (
        <>
            <Header></Header>
            <div className={"main"}>
                <div className={"slider-container"}>
                    <Slider></Slider>
                </div>
                <Notification></Notification>
                <div className={"slider-container"}>
                    <Slider2></Slider2>
                </div>
                <QuestionsListTabs ref={question}></QuestionsListTabs>
            </div>

        </>
    );
}

export default Main;