import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Main from "./components/Main/Main";
import { BrowserRouter as Router, Routes, Route, Link, useRoutes } from "react-router-dom";
import QuestionPage from "./pages/QuestionPage";
import QuestionPageAfterQuestion from "./pages/QuestionPageAfterQuestion";
import QuestionPageAfterQuestionGender from "./pages/QuestionPageAfterQuestionGender";
import QuestionPageAfterQuestionAge from "./pages/QuestionPageAfterQuestionAge";
import QuestionPageAfterQuestionMap from "./pages/QuestionPageAfterQuestionMap";
import Registration from "./pages/Registration";
import FirstQuestion from "./pages/firstQuestion";
import FirstQuestionResult from "./pages/firstQuestionResult";
import SubscribedUserQuestion from "./pages/subscribedUserQuestion";
import AnonimRoute from './AnonimRoute';
import PrivateRoute from './PrivateRoute';
import ListQestionPage from './pages/ListQestionPage';
import ListQestionPages from './pages/ListQestionPages';
import TextPage from './pages/TextPage';
function App() {

    return (
        <>

            <div className={"App"}>
                {/*<Pages></Pages>*/}
                <Router>
                    <Routes>
                        <Route element={< AnonimRoute />}>

                            <Route path="/reg" element={<Registration />} />

                        </Route>
                        <Route element={< PrivateRoute />} >
                            <Route path='/' element={<Main />} />
                            <Route path='/page' element={<TextPage />} />
                            <Route path='/listqvestions' element={<ListQestionPages />} />
                            <Route path='/listqvestion' element={<ListQestionPage />} />
                            <Route path='/questionpage' element={<QuestionPage />} />
                            <Route path='/QuestionPageAfterQuestion' element={<QuestionPageAfterQuestion />} />
                            <Route path='/QuestionPageAfterQuestionGender' element={<QuestionPageAfterQuestionGender />} />
                            <Route path='/QuestionPageAfterQuestionAge' element={<QuestionPageAfterQuestionAge />} />
                            <Route path='/QuestionPageAfterQuestionMap' element={<QuestionPageAfterQuestionMap />} />

                            <Route path='/firstQuestion' element={<FirstQuestion />} />
                            <Route path='/firstQuestionResult' element={<FirstQuestionResult />} />
                            <Route path='/subscribedUserQuestion' element={<SubscribedUserQuestion />} />

                        </Route>

                    </Routes>
                </Router>
            </div>

        </>
    );
}

export default App;
