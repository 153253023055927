import React from 'react';
import Header from "../components/Header/Header";
import './css.css';
import Footer from "../components/Footer/Footer";
import AfterQuestionGeneral from "../components/afterQuestion/afterQuestionGeneral/afterQuestionGeneral";
import AfterQuestionGeneralPercent from "../components/afterQuestion/afterQuestionGeneralPercent/afterQuestionGeneralPercent";
import TitleVotesTimeFavorite from "../components/afterQuestion/TitleVotesTimeFavorite/TitleVotesTimeFavorite";
import Pages from "../components/pages";

function QuestionPageAfterQuestion(props) {
    const submit = 'share question';

    return (
        <>
            <Header></Header>

            <div className={"main pageQuestion"}>
                <TitleVotesTimeFavorite></TitleVotesTimeFavorite>
                <AfterQuestionGeneral page={"default"}></AfterQuestionGeneral>
                <AfterQuestionGeneralPercent></AfterQuestionGeneralPercent>
                <Footer submitValue={submit}></Footer>
            </div>


        </>
    );
}

export default QuestionPageAfterQuestion;