import React, { useState, useEffect, useRef } from "react";
import Store from "../../Fech/Store";
import Get from '../../Fech/Get';
import "./Header.css";
import { Link } from "react-router-dom";

export const Header = (proops) => {
    const [isOpen, setOpen] = useState('');
    const [search, setSearch] = useState('');
    const [user, setUser] = useState({
        hash: "",
        country: "",
        gender: "",
        birthday: "",
        date: "",
        count: "0"
    });
    const [tags, setTags] = useState([]);
    const setSearchValue = (value) => {
        setSearch(value);
        let items = document.querySelectorAll('.value-block .value-item');
        items.forEach(item => {
            if (item.textContent.toLowerCase().includes(value.toLowerCase())) {
                item.classList.remove('hide-item');
            } else {
                item.classList.add('hide-item');

            }
        })
    }
    const getAllTags = (question) => {
        let tagArray = [];
        question.map((el) => {
            if (typeof el.tags == 'object' && el.tags !== null && el.tags.length > 0) {
                el.tags.map((tag) => {
                    tag.trim();
                    if (!tagArray.includes(tag)) {
                        tagArray.push(tag);
                    }

                })
            }

        });
        return tagArray.sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }
    const getQuestion = async () => {
        if (Store.Question.length == 0) {
            let data = await Get.post('get_questions_list', {
                key: Get.key,
                hash: Get.userhash,
                // tag: 'All'
            });
            Store.Question = data;
        }

        return Store.Question;
    }
    const getUser = async () => {
        if (Object.keys(Store.user).length == 0) {
            let user = await Get.post('get_user', {
                key: Get.key,
                hash: Get.userhash,
                // tag: 'All'
            });
            Store.user = user;
        }
        return Store.user;
    }
    const tooggleMenu = () => {
        if (isOpen == '') {
            setOpen('active');
        } else {
            setOpen('');
        }
    }
    useEffect(() => {
        const as = async () => {
            let user = await getUser();
            setUser(user);

            let data = await getQuestion();
            let tags = getAllTags(data);
            setTags(tags);

        }
        as()
    }, []);
    return (
        <header className={`header ${isOpen}`}>
            <div className="container">
                <div className="flex-betwen flex-wrap header-search-wrap">
                    <div className="menu-container-btn">
                        <button className="btn-menu" onClick={() => { tooggleMenu() }}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <div className="search-block">
                        <input type="text" placeholder="search or filter by tag" value={search} onChange={(e) => { setSearchValue(e.target.value) }} />
                        <div className="value-block">
                            <div className="value-text">
                                {tags.map((el, index) => {
                                    return <Link to={`/listqvestion?tag=${el}`} key={index} className="value-item" onClick={() => { setSearch(el) }}><span>{el}</span></Link>
                                })}
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-content">
                <div className="borders-content">
                    <div className="container">
                        <ul className="menu-list">
                            <li>
                                <a href="/">to main</a>
                            </li>
                            <li>
                                <a href="/listqvestions?pols=new">new polls</a>
                            </li>
                            <li>
                                <a href="/listqvestions?pols=hot">hot polls</a>
                            </li>
                            <li>
                                <a href="/listqvestions?pols=top">top polls</a>
                            </li>
                            <li>
                                <a href="/listqvestions?pols=voted">voted polls</a>
                            </li>
                            <li>
                                <a href="/listqvestions?pols=myown">my own polls</a>
                            </li>
                            <li>
                                <a href="/listqvestions?pols=favorites">favorites</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="borders-content">
                    <div className="container">
                        <ul className="menu-list">
                            <li className="grey-text">
                                gender: {user.gender}
                            </li>
                            <li className="grey-text">
                                date of birth: {user.birthday}
                            </li>
                            <li className="grey-text">
                                country: {user.country}
                            </li>
                            <li>
                                <Link to="/">
                                    copy for migrate to new device
                                </Link>
                                <span className="grey-text">(and we advise to save it)</span>
                            </li>
                            <li>
                                <Link to="/" className="copy-link">
                                    {user.hash}
                                </Link>

                            </li>
                        </ul>
                    </div>
                </div>
                <div className="borders-content">
                    <div className="container">
                        <ul className="menu-list">
                            <li>
                                <Link to="/page?page_id=7">privacy policy</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    terms of use
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="blue-bottom container">
                    <div className="text-block">
                        add your own questions for $0.99
                    </div>
                    <div className="btn-subscribe">
                        <Link className="btn-white" to="/"> subscribe</Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;