import React from 'react';
import FirstQuestionContent from '../components/firstQuestion/firstQuestion';
import Pages from "../components/pages";

function FirstQuestion(props) {
    return (
        <div>
            <FirstQuestionContent></FirstQuestionContent>


        </div>
    );
}

export default FirstQuestion;