import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import './QuestionsListTabs.css';
import Get from '../../Fech/Get';
import { Link } from "react-router-dom";

export const BasicTabs = (props, ref) => {
    const [value, setValue] = React.useState(0);
    const [questions, setQuestions] = useState([]);
    const [activeQvest, setActiveQvest] = useState([]);
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState(null);
    const [active, setActive] = useState(0);
    const getAllTags = (question) => {
        let tagArray = [];
        question.map((el) => {
            if (typeof el.tags == 'object' && el.tags !== null && el.tags.length > 0) {
                el.tags.map((tag) => {
                    tag.trim();
                    if (!tagArray.includes(tag)) {
                        tagArray.push(tag);
                    }

                })
            }

        });
        return tagArray.sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }
    const questionSet = (question) => {

        let tags = getAllTags(question);
        setTags(tags);

        setTag(tags[0]);
        setQuestions(question);
    }
    const sortInTags = (tag, index) => {
        setTag(tag);
        setActive(index)
    }
    const tagValue = (question, tag) => {
        let activeQuestion = [];
        question.map((el) => {
            if (typeof el.tags == 'object' && el.tags !== null && el.tags.includes(tag)) {
                activeQuestion.push(el);
            }

        })
        setActiveQvest(activeQuestion);
    }
    useImperativeHandle(ref, () => ({ questionSet }));
    useEffect(() => {
        tagValue(questions, tag)
    }, [tag]);

    return (
        <div className='tabs'>
            <div className='scroll-tags'>
                <div className='tag-container'>
                    {tags.map((tag, index) => {

                        return <div key={index} className={`scroll-tag ${active === index ? 'active' : ''}`} onClick={() => { sortInTags(tag, index) }}>
                            {tag}
                        </div>
                    })}
                </div>
            </div>
            <div className='scroll-questions container'>
                {activeQvest.map((question, index) => {
                    return <Link to={`/questionpage?id=${question.id}`} key={index} className='question-item'>
                        <div className='add-to-favorites'></div>
                        <div className='question-title'>{question.name}</div>
                        <div className='question-options'>
                            <div className='question-votes'>{question.respondents} votes</div>
                            <div className='question-data'>{Get.dateFormatTimestapTime(question.date)}</div>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    );
}
export default forwardRef(BasicTabs);